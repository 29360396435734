<template>
    <div class="course-close">
        <van-cell-group class="my-group">
            <van-cell class="corp" title="马场" icon="location-o" :value="info.corpName" />    
            <van-cell class="course"  title="课程" icon="award-o" :value="info.courseName" />    
            <van-cell class="date" title="日期" icon="underway-o" :value="info.billDate" />    
        </van-cell-group>
        <!-- 选择的上课时间和课程 -->
        <div class="course-time-list" v-show="selectCourseList.length !== 0">
            <div class="course-time-item" v-for="(item, i) in selectCourseList" :key="i">
                <div class="title">
                    <span class="name">时段{{(i+1) | CapParse}}</span>
                </div>
                <div class="item">
                    <div class="date">
                        <van-icon name="underway-o" />{{item.begTime}} ~ {{item.endTime}}
                    </div>
                    <div class="price">
                        <div>￥{{item.coursePrice}}/课x1</div>
                        <div class="color">{{item.realCoursePrice | money}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="coach">
                        <van-icon name="user-circle-o"/> {{item.coachName}}
                    </div>
                    <div class="price">
                        <div>￥{{item.coachPrice}}/课x1</div>
                        <div class="color">{{item.realCoachPrice | money}}</div>
                    </div>
                </div>
                <van-cell title="使用课时" :value="item.userVourch === 0 ? '不使用' : '使用'"/>
                <van-cell title="选择亲属" :value="item.relativeId ? item.relativeName : '暂无'"/>
            </div>
            <div class="xiaojie">
                <div class="item">
                    <span>总价</span>
                    <span>{{(totalPrice/100) | money}}</span>
                </div>
                <div class="item">
                    <span>课时</span>
                    <span>使用{{classNum}}鞍时抵扣{{deductionMoney}}元</span>
                </div>
                <div class="item">
                    <span>余额抵扣金额</span>
                    <span>{{balanceAmount | money}}</span>
                </div>
                <div class="item">
                    <span>应付</span>
                    <span>{{realyPay | money}}</span>
                </div>
            </div>
        </div>
         <!-- 结算 -->
        <van-submit-bar class="my-submit-bar" :price="realyPay*100" button-text="立即支付" @submit="onSubmitOrder" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectCourseList: [],
            info: {
                courseName: '',
                billDate: '',
                corpName: ''
            },
            balanceAmount: '',
            information: {}
        }
    },
    filters: {
        money(val) {
            let money = (val-0)
            return '￥' + money.toFixed(2);
        },
        CapParse(value) {
            let changeNum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'], newNum = '',
            arr = value.toString().split('');
            arr[0] = parseInt(arr[0]) - 1;
            if (arr[0] == -1 && arr.length == 1) { return '零'; }
            if (arr.length > 1) {
                arr[1] = parseInt(arr[1]) - 1;
                if (!arr[0]) {
                    newNum = !arr[0] && arr[1] == -1 ? changeNum[9] : changeNum[9] + changeNum[arr[1]];
                } else {
                    newNum = (changeNum[arr[0]] + changeNum[9]) + (changeNum[arr[1]] ? changeNum[arr[1]] : '')
                }
            } else {
                newNum = changeNum[arr[0]];
            }
            return newNum;
        },
    },
    computed: {
        totalPrice() {
            let total = 0
            this.selectCourseList.map(ele=>{
                total += (ele.coachPrice-0)+(ele.coursePrice-0)
            })
            return total*100
        },
        classNum() {
            let classNum = 0
            this.selectCourseList.map(ele=>{
                if(ele.userVourch === 1){
                    classNum++
                }
            })
            return classNum
        },
        deductionMoney() {
            let deductionMoney = 0
            this.selectCourseList.map(ele=>{
                if(ele.userVourch === 1){
                    deductionMoney += (ele.coursePrice-0)
                }
                if(ele.coachPriceFlag === 1){
                    deductionMoney += (ele.coachPrice-0)
                }
            })
            return deductionMoney
        },
        realyPay() {
           return (this.totalPrice/100 - this.deductionMoney - this.balanceAmount)
        }
    },
    methods: {
        getDetailsList() {
            this.$help.loading()
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-show', this.$route.query).then(res=>{
                // console.log(res)
                this.information = res
                this.info.corpName = res.corpName
                this.info.courseName = res.curriculumName
                this.info.billDate = res.billDate
                this.balanceAmount = res.balanceAmount
                res.curriculumTimeVOList.forEach(ele=>{
                    if(ele.cardId){
                        this.selectCourseList.push({
                            begTime: ele.begTime, 
                            endTime: ele.endTime,
                            coachId: ele.coachId,
                            cardId: ele.cardId,
                            billTime: ele.billTime,
                            coursePrice: ele.hourPrice, 
                            realCoursePrice: ele.hourPrice + ele.cardHourPrice, 
                            coachPrice: ele.coachAmount, 
                            realCoachPrice: ele.coachAmount + ele.cardCoachPrice, 
                            coachName: ele.coachName,
                            userVourch: 1,
                            coachPriceFlag: ele.cardHourPrice === 0 ? 0: 1,
                            relativeId: ele.relativeId,
                            relativeName: ele.relativeName,
                            relativeShip: ele.relativeShip,
                        })
                    }else{
                        this.selectCourseList.push({
                            begTime: ele.begTime, 
                            endTime: ele.endTime,
                            coursePrice: ele.hourPrice, 
                            realCoursePrice: ele.hourPrice + ele.cardHourPrice, 
                            coachPrice: ele.coachAmount, 
                            realCoachPrice: ele.coachAmount + ele.cardCoachPrice, 
                            coachName: ele.coachName,
                            userVourch: 0,
                            coachId: ele.coachId,
                            billTime: ele.billTime,
                            cardId: null,
                            coachPriceFlag: ele.cardHourPrice === 0 ? 0: 1,
                            relativeId: ele.relativeId,
                            relativeName: ele.relativeName,
                            relativeShip: ele.relativeShip,
                        })
                    }
                   
                })
                //this.selectCourseList = res.curriculumTimeVOList
                console.log(this.selectCourseList)
            })
            .finally(this.$help.loaded)
        },
        onSubmitOrder() {
            let form = {}
            form.curriculumId = this.information.curriculumId
            form.billDate = this.information.billDate
            form.corpId = this.information.corpId
            form.balanceAmount = this.information.balanceAmount
            form.payAmount = this.information.payAmount
            let arr = []
            this.selectCourseList.forEach(ele=>{
                if(ele.cardId){
                    arr.push({
                        billCardId: ele.cardId,
                        coachId: ele.coachId,
                        billTime: ele.billTime,
                        relativeId: ele.relativeId,
                        relativeName: ele.relativeName,
                        relativeShip: ele.relativeShip,
                    })
                }else{
                    arr.push({
                        coachId: ele.coachId,
                        billTime: ele.billTime,
                        relativeId: ele.relativeId,
                        relativeName: ele.relativeName,
                        relativeShip: ele.relativeShip,
                    })
                }
            })
            this.$set(form, 'curriculumTimeVOList', arr)
            console.log(form)
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
        }
    },
    mounted() {
        this.getDetailsList()
        console.log(123)
    }
}
</script>


<style lang="scss">
    @import "~@/assets/css/var";
    .course-close{
        .course-time-list{
            //padding-bottom: 50px;
            .btn{
               padding: 10px 40px 20px; 
               .van-button{
                    background-color: $main;
                    border: 0;
                }
            }
            .course-time-item{
                margin: 20px;
                padding: 10px;
                box-shadow: 0 0 6px 9px $boxShadow;
                border-radius: 19px;
                background-color: #fff;
                .title{
                    text-align: center;
                    font-size: 15px;
                    padding-bottom: 10px;
                    position: relative;
                    border-bottom: 1px solid #ccc;
                    .van-icon{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        font-size: 20px;
                        color: $gray;
                    }
                    .name{ 
                        padding-right: 5px;
                    }
                }
                .item{
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    border-bottom: 1px solid #ccc;
                    .color{
                        color: $red;
                        text-align: right;
                    }
                }
                .van-cell {
                    padding: 10px;
                }
            }
            .xiaojie{
                margin: 10px 20px 70px;
                padding: 10px;
                background-color: #fff;
                border-radius: 19px;
                box-shadow:  0 0 6px 9px $boxShadow; 
                .item{
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }        
        .my-submit-bar{
            .van-button{
                background: $main;
                border: 0;
            }
            .van-submit-bar__text{
                text-align: unset;
            }
        }
    }



</style>